<template>
  <div
    :class="[
      'col',
      {
        'nav-hidden': navHidden,
        'desk-hidden': desktopHidden,
        'lap-hidden': laptopHidden,
        'tab-hidden': tabletHidden,
        'mob-hidden': mobileHidden,
        'tiny-hidden': tinyMobileHidden
      },
      fill ? 'fill' : '',
      columnWidth ? 'w' + columnWidth : '',
      pad ? 'pad2' : '',
      top ? 'align-top' : '',
      middle ? 'align-middle' : '',
      bottom ? 'align-bottom' : '',
      left ? 'text-left' : '',
      right ? 'text-right' : '',
      center ? 'text-center' : '',
      desktop ? 'lap-hidden tab-hidden mob-hidden' : '',
      laptop ? 'desk-hidden tab-hidden mob-hidden' : '',
      tablet ? 'desk-hidden lap-hidden mob-hidden' : '',
      mobile ? 'desk-hidden lap-hidden tab-hidden' : '',
      tinyMobile ? 'desk-hidden lap-hidden tab-hidden mob-hidden' : '',
      belowNavWidth ? 'below-nav-' + belowNavWidth : '',
      belowDesktopWidth ? 'below-desk-' + belowDesktopWidth : '',
      belowLaptopWidth ? 'below-lap-' + belowLaptopWidth : '',
      belowTabletWidth ? 'below-tab-' + belowTabletWidth : '',

      belowNavMiddle ? 'below-nav-align-middle' : '',
      belowDesktopMiddle ? 'below-desk-align-middle' : '',
      belowLaptopMiddle ? 'below-lap-align-middle' : '',
      belowTabletMiddle ? 'below-tab-align-middle' : '',

      belowNavTop ? 'below-nav-align-top' : '',
      belowDesktopTop ? 'below-desk-align-top' : '',
      belowLaptopTop ? 'below-lap-align-top' : '',
      belowTabletTop ? 'below-tab-align-top' : '',

      belowNavBottom ? 'below-nav-align-bottom' : '',
      belowDesktopBottom ? 'below-desk-align-bottom' : '',
      belowLaptopBottom ? 'below-lap-align-bottom' : '',
      belowTabletBottom ? 'below-tab-align-bottom' : '',

      belowNavTextCenter ? 'below-nav-text-center' : '',
      belowDesktopTextCenter ? 'below-desk-text-center' : '',
      belowLaptopTextCenter ? 'below-lap-text-center' : '',
      belowTabletTextCenter ? 'below-tab-text-center' : '',

      belowNavTextLeft ? 'below-nav-text-left' : '',
      belowDesktopTextLeft ? 'below-desk-text-left' : '',
      belowLaptopTextLeft ? 'below-lap-text-left' : '',
      belowTabletTextLeft ? 'below-tab-text-left' : '',

      belowNavTextRight ? 'below-nav-text-right' : '',
      belowDesktopTextRight ? 'below-desk-text-right' : '',
      belowLaptopTextRight ? 'below-lap-text-right' : '',
      belowTabletTextRight ? 'below-tab-text-right' : '',

      navWidth ? 'nav-' + navWidth : '',
      desktopWidth ? 'desk-' + desktopWidth : '',
      laptopWidth ? 'lap-' + laptopWidth : '',
      tabletWidth ? 'tab-' + tabletWidth : '',
      mobileWidth ? 'mob-' + mobileWidth : '',
      tinyMobileWidth ? 'tiny-' + tonyMobileWidth : ''
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    navHidden: Boolean,
    desktopHidden: Boolean,
    laptopHidden: Boolean,
    tabletHidden: Boolean,
    mobileHidden: Boolean,
    tinyMobileHidden: Boolean,

    pad: Boolean,

    top: Boolean,
    middle: Boolean,
    bottom: Boolean,

    left: Boolean,
    right: Boolean,
    center: Boolean,

    // Visible only on these breakpoints
    nav: Boolean,
    desktop: Boolean,
    laptop: Boolean,
    tablet: Boolean,
    mobile: Boolean,
    tinyMobile: Boolean,

    // Below a certain breakpoint, set the width
    belowNavWidth: Number,
    belowDesktopWidth: Number,
    belowLaptopWidth: Number,
    belowTabletWidth: Number,

    belowNavTop: Boolean,
    belowDesktopTop: Boolean,
    belowLaptopTop: Boolean,
    belowTabletTop: Boolean,

    belowNavMiddle: Boolean,
    belowDesktopMiddle: Boolean,
    belowLaptopMiddle: Boolean,
    belowTabletMiddle: Boolean,

    belowNavBottom: Boolean,
    belowDesktopBottom: Boolean,
    belowLaptopBottom: Boolean,
    belowTabletBottom: Boolean,

    belowNavTextLeft: Boolean,
    belowDesktopTextLeft: Boolean,
    belowLaptopTextLeft: Boolean,
    belowTabletTextLeft: Boolean,

    belowNavTextCenter: Boolean,
    belowDesktopTextCenter: Boolean,
    belowLaptopTextCenter: Boolean,
    belowTabletTextCenter: Boolean,

    belowNavTextRight: Boolean,
    belowDesktopTextRight: Boolean,
    belowLaptopTextRight: Boolean,
    belowTabletTextRight: Boolean,

    navWidth: Number,
    desktopWidth: Number,
    laptopWidth: Number,
    tabletWidth: Number,
    mobileWidth: Number,
    tinyMobileWidth: Number,

    // Set column width directly to any set size
    // 10 20 25 30 33 40 50 60 66 70 75 80 90 100
    columnWidth: Number,

    // If true adds flex: 1 to take up any remaining space
    fill: Boolean
  }
};
</script>
