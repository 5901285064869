<template>
  <div class="team top">
    <Section class="hero hero-image history" top>
    </Section>
    
    <Section class="big-info gradient-back step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100" class="hero-text">
          <h1>
            Wolstanton Conservative Club 
            <br> 
            History & News
          </h1>

          <p>
            Wolstanton Conservative Club in Lily Street, Wolstanton, Newcastle-under-Lyme, celebrated its 136th anniversary with a special party held on April 30th. The club was founded in the village in 1886, moving to its present location in 1891.  Surviving committee minutes record that the club once boasted billiards, a skittle alley and a rifle club, but today’s premises offer a smart snooker room and a cozy concert room that regularly plays host to musicians as the club consolidates its reputation as one of Wolstanton’s longest-surviving institutions.
          </p>

          <img class="history-img" :src="scrollHoldersImgUrl" />
          <p>
            (From Left to Right) <em>Mr Stan Tilstone, John Cooper</em> (Club Chairman) <em>Jane Jepson</em> (Club President) <em>Len Kelsall and Derek Boulton</em> <i><br> <b>(All the men are ‘Scroll’ holders – 50+years Continuous Membership)</b>
          </i></p>

          <img class="history-img" :src="groupImgUrl" />
          <p>
            <em>Group Photo of some members who attended the Celebrations.</em>
          </p>

          <hr>
          <p>
            A meeting took place at the National Schools in Wolstanton in December, 1885 in order to celebrate the formation of a Conservative Association in the village.
            <br> 
            <br> 
            The Wolstanton Conservative Club was founded in 1886.  The club was situated in Wood Terrace, Wolstanton High Street in a dwelling-house converted into suitable premises.  This opened in February, 1886, but even at the time, it was predicted that the club would outgrow the premises.  Sure enough, it did, growing from about a dozen members to around 110.
            <br>
            <br> 
            In 1891, a house and shop in Lily Street, lately occupied by a butcher, Mr. Holt, was taken on a 21-year lease, and Charles Challinor of Basford Hall provided £300 to convert it into a club house.   Challinor was a colliery owner, and his death in 1893 followed an accident involving a steam engine at the Glebe Colliery in Fenton.
            <br>
            <br> 
            The work on the Conservative Club was done by Ralph Dain and Sons, the Burslem architects, and N. Bennett, builder, of Burslem.
            <br>
            <br> 
            The room on the left offered refreshments and non-alcoholic drinks, whilst the large room on the right contained a billiard-table and platforms for spectators.  Upstairs were a reading room, smoke room and committee room.  At the rear was a commodious hall of 60 feet by 40 feet which served as a skittle alley. There was also accommodation for the steward and stewardess.
            <br>
            <br> 
            The club, from its opening, provided entertainment in the way of music and dance – a means of giving young men a means of hearty amusement, and an alternative to idleness.  As was noted then – by D. H. Coghill, MP for Newcastle – the club was an alcohol-free establishment.  Like many other establishments in Newcastle and the Potteries, its aim was to morally improve young men through providing alternative amusement to drinking establishments.
            <br>
            <br> 
            Relatively few of the original club minute books survive, though we do have interesting references to the running of the club in times past.  They include the formation of a miniature rifle club in 1904, the banning of the game of poker in 1921, the sale of Worthington E bitter as the single house beer in 1924, the ruling that no dogs should be allowed in the club in 1928 and the matter of legal proceedings against nearby Wolstanton Colliery for the mining subsidence that had damaged the club building.
            <br>
            <br> 
            These high standards have been retained by the Conservative Club at Wolstanton, but today you will find a relaxed atmosphere, a smart interior that plays host to music evenings and above all a warm welcome to all visitors.
          </p>          
        </Column>
        <h2>
          Researched and written by Mervyn Edwards, local historian.
        </h2>
      </Grid>      
    </Section>

    
  </div>
</template>

<script>
export default {
  name: "visiting",
  components: {},
  data() {
    return {
      scrollHoldersImgUrl: "/images/committee/3.jpg",
      groupImgUrl: "/images/committee/2.jpg",
    };
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.invert .quote blockquote {
  color: rgb(243, 230, 230);
}

.second-image {
  margin-top: 5em;
}

.history-img {
  height: auto;
  width: 35em;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  backdrop-filter: blur(16.5px);
}

.quote {
  padding: 1em 5em;

  @include media($breakpoint-below-tablet) {
    padding: 1em 0em;
  }

  blockquote {
    font-family: Georgia, serif;
    font-style: italic;

    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;

    @include media($breakpoint-below-tablet) {
      padding-right: 10px;
    }
  }

  blockquote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
  }

  blockquote cite {
    color: #999999;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  blockquote cite:before {
    content: "\2014 \2009";
  }
}

</style>
