<template>
  <div :class="['background-item', dark ? 'dark' : '']" ref="block">
    <div
      class="background"
      :style="{ backgroundImage: 'url(' + image + ')', opacity: opacity }"
    ></div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    opacity: Number,
    dark: Boolean
  }
};
</script>

<style lang="scss" scoped>
.background-item {
  background-color: var(--color-background);

  &.dark {
    background-color: var(--color-dark-background);
    color: var(--color-dark-background-text);
  }

  height: 100%;

  position: relative;

  // Needed to fix width of both div after being absolute
  > div {
    position: absolute;
    width: 100%;

    // Make them both full
    height: 100%;
  }

  .background {
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-position: center center;

    @include media($breakpoint-below-desktop) {
      background-attachment: inherit;
    }
  }
}
</style>
