<template>
  <div class="home top">
    <ViewportHeight>
      <FullBackground class="hero" :image="splashImageUrl">
        <Section center middle pad wrap>
          <Grid center class="height100">
            <Column
              center
              middle
              :columnWidth="66"
              below-tablet-text-center
              :below-tablet-width="100"
              class="hero-header-text"
            >
              <h1>
                Wolstanton Conservative Club
                <span class="text-no-wrap">welcomes you</span>
              </h1>
              <p>
                Situated in the heart of Wolstanton, Newcastle-under-Lyme, we provide fantastic facilities for our members & guests.
                From live entertainment on our dedicated stage, to Snooker, Cribbage and Darts in our Game Room.
              </p>
            </Column>
          </Grid>
        </Section>
      </FullBackground>
    </ViewportHeight>

    <Section class="big-info image-text gradient-back" wrap pad>
      <Grid pad center middle>
        <Column center :below-laptop-width="100" :column-width="50">
          <img :src="iconBarUrl" class="image-border" />
        </Column>
        <Column
          :below-laptop-width="100"
          :column-width="50"
          below-laptop-text-center
        >
          <h1>
            Rest & Relax
          </h1>
          <p>
            If relaxing with a refreshing drink in pleasant surroundings is to your taste, 
            then we have just what you are looking for with our very own <em>Bar Area</em>. You can pull up a 
            stool and enjoy many draught beers and a selection of Bottled Real Ales, 
            along with wines and soft drinks. You can even take your drinks with you into our <em>Beer Garden</em>, 
            where you can listen to the bird songs.
          </p>
        </Column>
      </Grid>
    </Section>

    <Section class="pl2 pr2" wrap center>
      <div class="iconic-grid" pad>
        <Column >
          <div class="iconic-header">
            <h3>Live</h3>
            <p>Entertainment</p>
          </div>
        </Column>
        <Column >
          <div class="iconic-header">
            <h3>100+</h3>
            <p>Seating</p>
          </div>
        </Column>
        <Column >
          <div class="iconic-header">
            <h3>Local</h3>
            <p>Teams</p>
          </div>
        </Column>
        <Column >
          <div class="iconic-header">
            <h3>Snooker</h3>
            <p>Tables</p>
          </div>
        </Column>
      </div>
    </Section>

    <Section center pad wrap>
      <Grid pad center middle>
        <Column 
          :below-laptop-width="80"
          :column-width="50"
          below-laptop-text-center>
          <div class="big-info">
            <p style="color: var(--color-tertiary)">
              We hold regular live entertainment events at the club. Click the button below to find out more.
            </p>
            <div class="button-cont">
              <router-link to="/entertainment" class="button big" style="margin-bottom: unset !important;">Entertainment Page</router-link>
            </div>
          </div>
        </Column>
      </Grid>
    </Section>

    <!-- Luke -->
    <Section class="big-info works gradient-back invert" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            Room Hire
          </h1>
          <p>
            Our club house has a wide variety of facilities available for events, such as our fully equipped 
            <em>Stage & Dance floor</em> where we hold many of our live shows. We also have a dedicated <em>Games Room</em> that
            has two snooker tables, as well as dart board and cribbage boards. If you prefer a more relaxed 
            experience we have our very own <em>Conservatory & Beer Garden</em> where you can enjoy the pleasent surroundings with your friends.
          </p>
        </Column>
      </Grid>
            
      <Grid center>
        <Column class="facilities" :below-laptop-width="80">
          <img :src="club2Url" />
          <h2><u>Snooker</u></h2>
          <p>
            We are immensely proud of our two full-sized snooker tables. As a club we hold regular snooker events throughout the year,
            with many of our members & guests taking part in local and regional tournaments.
          </p>
        </Column>
        <Column class="facilities" :below-laptop-width="80">
          <img :src="club3Url" />
          <h2><u>Darts</u></h2>
          <p>
            Why not go for the Bullseye with our local league teams? We have a number of club teams that take part in
           tournaments all year round.
          </p>
        </Column>
        <Column class="facilities" :below-laptop-width="80">
          <img :src="club4Url" />
          <h2><u>Conservatory & Beer Garden</u></h2>
          <p>
            Take the weight off your feet and enjoy the relaxation of our Conservatory with a wonderful view of our Beer Garden.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="club5Url"> </FullBackground>
    </div>

    <!-- Client A -->
    <Section class="big-info works gradient-back" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            Opening <br/>
            <em>Hours</em>
          </h1>
          <p>
            As a club we are very proud of all the wonderful entertainment and facilities 
            we provide to our members & guests, and you may wish to come along and join as a member too. You will find the club open during the
            times set out below. 
          </p>
        </Column>
      </Grid>

      <Grid center middle>
        <Column :column-width="60" :below-laptop-width="100">
        <!-- Hours Section -->
        <div class="hours" id="hours">
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
              <div class="business-hours">
                <ul class="list-unstyled opening-hours" style="text-align: left;">
                  <li>
                    Monday
                    <span class="pull-right">7:30pm - 11:00pm</span>
                  </li>
                  <li>
                    Tuesday
                    <span class="pull-right">7:30pm - 11:00pm</span>
                  </li>
                  <li>
                    Wednesday
                    <span class="pull-right">7:30pm - 11:00pm</span>
                  </li>
                  <li>
                    Thursday
                    <span class="pull-right">7:30pm - 11:00pm</span>
                  </li>
                  <li>
                    Friday
                    <span class="pull-right">7:00pm - 11:30pm</span>
                  </li>
                  <li>
                    Saturday & Sunday Lunch
                    <span class="pull-right">12:30pm - 3:00pm</span>
                  </li>
                  <li>
                    Saturday & Sunday Evening
                    <span class="pull-right">7:30pm - 11:00pm</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-4"></div>
          </div>
        </div>
        </Column>
      </Grid>
      <h1>Become a <em>member</em> today!</h1>
      <p>
        Are you interested in applying for membership at the <b>Wolstanton Conservative Club</b>? You can speak to our secretary via our contact page,
        simply click the button below to fill out our online form.
      </p>
      <div class="button-cont">
        <router-link to="/contact" class="button big" style="margin-bottom: unset !important;">Contact Page</router-link>
      </div>
    </Section>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/heros/home.jpg",
      iconBarUrl: "/images/club/bar-1.jpg",
      club1Url: "/images/club/bar-2.jpg",
      club2Url: "/images/club/billiards.jpg",
      club3Url: "/images/club/darts.jpg",
      club4Url: "/images/club/summer-room.jpg",
      club5Url: "/images/club/garden.jpg"
    };
  }  
};
</script>

<style lang="scss">

.home .background-item {
  background-color: black !important;
}

.home {
  .hero {
    color: white;
  }
}

.image-text {
  img {
    width: 80%;
    @include media($breakpoint-below-laptop) {
      width: 70% !important;
    }
  }
}

.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.iconic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  grid-gap: 1.5em;

  @include media($breakpoint-below-laptop) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    grid-gap: 1.5em;
  }
  @include media($breakpoint-below-tablet) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    grid-gap: 1.5em;
  }    
}

.iconic-header {
  padding: 1em;
  border-radius: 25px;
  background: linear-gradient(145deg, #4f95c0, #001725);
  -webkit-box-shadow: 27px 27px 55px #153c55, -27px -27px 55px #256e9b;
  box-shadow: 8px 8px 10px 0px #153c55, 0px 0px 12px #256e9b;

  h3 {
    font-size: 3em;
    margin: 0;
    line-height: 1.2em;
    color: white;

    @include media($breakpoint-below-laptop) {
      font-size: 2.5em;
    }
  }
  p {
    font-weight: bold;
    font-size: 2.5em;
    color: white;
    @include media($breakpoint-below-laptop) {
      font-size: 1.2em;
    }
    margin: 0;
  }
}
</style>
