import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

import "normalize.css";

// Globally available components
import Grid from "@/components/core/layout/Grid.vue";
import Column from "@/components/core/layout/Column.vue";

import GradientUnderline from "@/components/core/elements/GradientUnderline.vue";
import TextImageSideBy from "@/components/core/elements/TextImageSideBy.vue";
import ShadowBox from "@/components/core/elements/ShadowBox.vue";
import Spacer from "@/components/core/elements/Spacer.vue";
import Accordion from "@/components/core/elements/Accordion.vue";
import AccordionItem from "@/components/core/elements/AccordionItem.vue";

import NavigationMenu from "@/components/core/navigation/NavigationMenu.vue";
import MenuList from "@/components/core/navigation/MenuList.vue";
import MenuListItem from "@/components/core/navigation/MenuListItem.vue";

import BlockCentered from "@/components/core/containers/BlockCentered.vue";
import Section from "@/components/core/containers/Section.vue";
import FullBackground from "@/components/core/containers/FullBackground.vue";
import ViewportHeight from "@/components/core/containers/ViewportHeight.vue";
import FillCentered from "@/components/core/containers/FillCentered.vue";

Vue.component("Grid", Grid);
Vue.component("Column", Column);
Vue.component("Section", Section);
Vue.component("GradientUnderline", GradientUnderline);
Vue.component("TextImageSideBy", TextImageSideBy);
Vue.component("NavigationMenu", NavigationMenu);
Vue.component("MenuList", MenuList);
Vue.component("MenuListItem", MenuListItem);
Vue.component("BlockCentered", BlockCentered);
Vue.component("ShadowBox", ShadowBox);
Vue.component("Spacer", Spacer);
Vue.component("Accordion", Accordion);
Vue.component("AccordionItem", AccordionItem);
Vue.component("FullBackground", FullBackground);
Vue.component("ViewportHeight", ViewportHeight);
Vue.component("FillCentered", FillCentered);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
