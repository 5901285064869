<template>
  <div class="contact top">
    <div top>
      <Section class="big-info gradient-back" center wrap pad>
        <Grid center>
          <Column middle :column-width="66" :mobile-width="100" class="hero-text">
            <h1>Want to <em>get in touch?</em></h1>
            <p>
              Please feel free to call, email or use the contact form below.
            </p>
            <a class="button" href="tel:01782616863">Call Us</a>
            <a
              class="button alternative"
              target="_blank"
              href="mailto:thesecretary@wolstantoncon.plus.com"
              >Email Us</a
            >
          </Column>
        </Grid>
      </Section>
    </div>

    <Section center class="address">
      <Grid>
        <Column>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2399.324930342582!2d-2.2205390841733577!3d53.032497379914474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1a3ec4a77219847a!2sWolstanton%20Conservative%20Club!5e0!3m2!1sen!2suk!4v1638207401260!5m2!1sen!2suk"
            width="100%"
            height="450"
            frameborder="0"
            style="border:0;"
            loading="lazy"
            allowfullscreen=""
          ></iframe>
        </Column>
      </Grid>
    </Section>

    <Section pad wrap class="above-contact-form gradient-back">
      <div class="text-center">
        <h1 class="tight">Send Us A Message</h1>
        <p class="pb2">
          If you want to write a quick message and send it to me simply fill out
          the form below
        </p>
      </div>

      <Grid center>
        <Column class="contact-form-container" :column-width="70" :below-tablet-width="100">
          <Grid pad :class="['contact-form', errors ? 'errors' : '']">
            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!nameError" for="name">Name</label>
                <label v-if="nameError" class="errorLabel" for="name"
                  >* You must enter a name</label
                >

                <input
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  :class="[nameError ? 'error' : '']"
                  v-model="name"
                  required
                />
              </div>
            </Column>
            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label for="company">Company</label>
                <input
                  type="text"
                  v-model="company"
                  placeholder="Company Name"
                  name="company"
                />
              </div>
            </Column>

            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!emailError" for="email">Email</label>
                <label v-if="emailError" class="errorLabel" for="email"
                  >* You must enter either a phone or valid email</label
                >
                <input
                  type="email"
                  v-model="email"
                  :class="[emailError ? 'error' : '']"
                  placeholder="Email"
                  name="email"
                />
              </div>
            </Column>

            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!telephoneError" for="telephone">Telephone</label>
                <label v-if="telephoneError" class="errorLabel" for="telephone"
                  >* You must enter either a phone or valid email</label
                >
                <input
                  type="number"
                  :class="[telephoneError ? 'error' : '']"
                  placeholder="Phone Number"
                  name="telephone"
                  v-model="telephone"
                />
              </div>
            </Column>

            <Column>
              <div class="form-group-full">
                <label v-if="!messageError" for="message">Your Message</label>
                <label v-if="messageError" class="errorLabel" for="message"
                  >* You must enter a message</label
                >
                <textarea
                  name="message"
                  placeholder="Your Message"
                  :class="[messageError ? 'error' : '']"
                  rows="8"
                  v-model="message"
                ></textarea>
              </div>
            </Column>

            <Column :column-width="100">
              <a
                :class="['button', sending ? 'sending' : '']"
                href="#"
                @click.prevent="sendMessage"
                >{{ sendButtonText }}</a
              >

              <p
                :class="[
                  'label',
                  errors || sendFailure ? 'error' : 'success',
                  !sendSucceeded && !errors && !sendFailure ? 'hide' : ''
                ]"
              >
                &nbsp;
                <span v-if="errors"
                  >Please fix the issues above and click
                  <b>Send Message</b> again</span
                >
                <span v-if="sendSucceeded"
                  >Your message was successfully sent. We will get back to you
                  shortly</span
                >

                <span v-if="sendFailure">{{ sendFailure }}</span>
              </p>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Section>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      splashImageUrl: "/images/backgrounds/map-background.jpg",
      name: "",
      company: "",
      email: "",
      telephone: "",
      message: "",
      nameError: false,
      emailError: false,
      telephoneError: false,
      messageError: false,
      sending: false,
      sendFailure: "",
      sendSucceeded: false
    };
  },
  computed: {
    errors() {
      return (
        this.nameError ||
        this.emailError ||
        this.telephoneError ||
        this.messageError
      );
    },
    sendButtonText() {
      return this.sending ? "Sending..." : "Send Message";
    }
  },
  methods: {
    validEmail(email) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    isEmptyText(input) {
      return input == null || input.trim() === "";
    },
    sendMessage() {
      // If we are already sending...
      if (this.sending)
        // Ignore
        return;

      // Store this
      let me = this;

      // Clear send errors
      this.sendFailure = "";
      this.sendSucceeded = false;

      // Check we have a name
      this.nameError = this.isEmptyText(this.name);

      // Email or telephone must be provided
      this.emailError = this.telephoneError =
        !this.validEmail(this.email) && this.isEmptyText(this.telephone);

      // Check we have a message
      this.messageError = this.isEmptyText(this.message);

      // If we have any errors...
      if (this.errors) {
        // Scroll to top of form
        document.querySelector(".above-contact-form").scrollIntoView({
          behavior: "smooth",
          block: "end" // scroll to bottom of target element
        });

        // Stop there
        return;
      }

      // Good to go... try sending email and setting sending status
      this.sending = true;

      this.axios
        .post("/sendemail", {
          name: this.name,
          company: this.company,
          email: this.email,
          telephone: this.telephone,
          message: this.message
        })
        .then(function(response) {
          // No longer sending
          me.sending = false;

          console.log("got response");
          console.log(response);

          // Make sure response says "Sent ok :)"
          if (response.data == "Sent ok :)") {
            // All good
            me.sendSucceeded = true;

            // Clear all fields
            me.name = me.company = me.email = me.telephone = me.message = "";
          } else {
            // Doh... unknown response
            me.sendFailure = "Unknown response from mail sender server.";
          }
        })
        .catch(function(error) {
          // No longer sending
          me.sending = false;

          console.log("got error");
          console.log(error.response);

          // Doh... show error
          me.sendFailure = me.isEmptyText(error.response.data)
            ? error.response.statusText
            : error.response.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  //transition: opacity 0.5s;

  display: block;
  text-align: center;
  border-radius: 0.5em;
  padding: 1em;
  font-size: 0.9em;
  border: 1px solid;
  margin-top: 1.5em;

  &.hide {
    opacity: 0;
  }

  &.error {
    background: rgb(255, 232, 232);
    border-color: red;
    color: red;
  }

  &.success {
    background: rgb(232, 255, 232);
    border-color: green;
    color: green;
  }
}

.errors .button {
  background: red;
}

.button.sending {
  cursor: progress;
  background: green;
  &:hover {
    background: green;
  }
}

.contact-form {
  label {
    display: block;
    padding-bottom: 0.5em;
  }

  input,textarea {
    color: black;
    font-style: normal;
    font-weight: bold;
  }

  .errorLabel {
    color: red;
  }

  input,
  textarea {
    &.error {
      border-color: red !important;
      background: rgb(255, 232, 232);

      &::placeholder {
        color: red;
      }
    }

    width: 100%;
    padding: 1em;
    border: 1px solid #aaa;

    &:focus {
      outline: none !important;
      border-color: var(--color-primary);
    }
  }
}

em {
  color: var(--color-primary);
  font-style: normal;
}

.phone {
  padding-top: 3em;
  padding-bottom: 3em;
  h2 {
    font-size: 1em;
    color: var(--color-foreground-lighter);
    text-transform: uppercase;
  }

  h2 + p {
    font-size: 3em;
    margin: 0;
    font-weight: bold;
  }

  p {
    font-size: 1.5em;
  }
}

.hero {
  border-bottom: 2px solid;
  border-color: var(--color-primary);

  h1 {
    margin-bottom: 0.8em;
  }

  h1 + p {
    margin-bottom: 2em;
  }
}
</style>
