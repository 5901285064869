<template>
  <div class="team top">
    <!-- Hero -->
    <Section class="big-info gradient-back step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100" class="hero-text">
          <h1>Wolstanton Conservative Club <br> Committee</h1>
          <p>Our Committee Members at Wolstanton Conservative Club are here to provide for all the members & guests,
             and to ensure the smooth running of the club so our members & guests can enjoy their membership.
             If you have any questions or concerns please feel free to contact any one of us, 
             and we will do our best to ensure it is answered as swiftly as possible.</p>
        </Column>
        <Column :column-width="80" :below-laptop-width="100" class="hero-text">
          <div class="committee-image-container">
            <img :src="committeeURL">
          </div>
        </Column> 
      </Grid>
      
    </Section>

    
  </div>
</template>

<script>
export default {
  name: "visiting",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/committee/roll-of-honour.jpg",
      committeeURL: "/images/committee/committee.jpg",
    };
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.invert .quote blockquote {
  color: rgb(243, 230, 230);
}

.second-image {
  margin-top: 5em;
}

.quote {
  padding: 1em 5em;

  @include media($breakpoint-below-tablet) {
    padding: 1em 0em;
  }

  blockquote {
    font-family: Georgia, serif;
    font-style: italic;

    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;

    @include media($breakpoint-below-tablet) {
      padding-right: 10px;
    }
  }

  blockquote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
  }

  blockquote cite {
    color: #999999;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  blockquote cite:before {
    content: "\2014 \2009";
  }
}

</style>
