<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    // If true, takes this elements start Y position and adds the difference
    // to make it fit to the bottom of the screen (used for elements at the top of
    // the screen perhaps just below a nav bar)
    top: Boolean,
    // Specifies the percentage of the viewport height (can be above 100)
    percentage: Number,
    // If true, set min-height. Otherwise set height
    minHeight: Boolean
  },
  mounted() {
    let windowHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    // If a percentage is specified...
    if (this.percentage)
      // Work out percentage
      windowHeight *= this.percentage / 100;

    let desiredHeight =
      windowHeight - (this.top ? this.$el.offsetTop : 0) + "px";

    // Set height
    if (this.minHeight) this.$el.style.minHeight = desiredHeight;
    else this.$el.style.height = desiredHeight;
  }
};
</script>
