<template>
  <Column :fill="spacer" :below-nav-width="100" middle class="menu-item">
    <router-link v-if="!spacer" :to="url">
      <slot></slot>
    </router-link>
  </Column>
</template>

<script>
export default {
  props: {
    url: String,
    // If the column should act as a spacer to fill all unused space
    spacer: Boolean
  }
};
</script>

<style lang="scss" scoped>
// For each menu item
.menu-item {
  // Set font size
  a {
    // Smooth transition
    transition: padding 0.2s ease, font-size 0.2s ease, color 0.2s ease,
      transform 0.2s ease-in-out;

    transform: scale(1);

    // Allow padding
    display: inline-block;

    // Padding for larger click area
    padding: 0.5em 1em;

    // Set font size
    font-size: 1.2em;

    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.08em;
      bottom: 0;
      left: 0;
      background-color: var(--color-primary);
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    &:hover::before {
      transform: scaleX(1);
    }

    @include media($breakpoint-below-laptop) {
      font-size: 2.2em;
    }
    @include media($breakpoint-below-tablet) {
      font-size: 1.2em;
    }

    // Highlight on hover
    &:hover {
      //transform: scale(1.2) rotate(-6deg);
      //text-decoration: underline;
    }
  }  

  // Text centered below desktop
  @include media($breakpoint-below-nav) {
    text-align: center;
  }
}
</style>
