<template>
  <div class="weddings top">
    <!-- Client B -->
    <Section class="big-info pt2 works gradient-back" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100" class="hero-text">
          <h1>
            <em>Gallery</em>
          </h1>
          <p>
            The Wolstanton Conservative Club has a long and proud history, and we regularly like to capture memories so that we can look back at all the fond memories.
            Please feel free to click on any of the images to make them larger. If you have any photographs you would like us to show here please contact the secretary.
          </p>
        </Column>
      </Grid>
      <Grid center>
        <Column :below-laptop-width="100">
          <div class="sub-heading-text">
            <h3>Click a card below to open the gallery</h3>    
          </div>
          <LightGallery
            :images="images"
            :index="index"
            :disable-scroll="true"
            @close="index = null"
          />

          <Grid pad class="gallery-container">
            <Column
              :column-width="33"
              v-for="(image, imageIndex) in images"
              :key="imageIndex"
              @click="index = thumbIndex"
            >
              <div
                class="image-box"
                @click="index = imageIndex"
                :style="{ backgroundImage: 'url(' + image + ')' }"
              ></div>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Section>

    
  </div>
</template>

<script>
// @ is an alias to /src
import { LightGallery } from "vue-light-gallery";

export default {
  name: "gallery",
  components: {
    LightGallery
  },
  data() {
    return {
      images: [
        "/images/committee/3.jpg",
        "/images/committee/2.jpg",
        "/images/committee/4.jpg",
        "/images/committee/committee.jpg",
        "/images/committee/committee-member.jpg",
        "/images/club/bar-3.jpg",
        "/images/club/bar-2.jpg",
        "/images/club/bar-1.jpg",
        "/images/club/billiards.jpg",
        "/images/club/lounge.jpg",
        "/images/club/summer-room.jpg",
        "/images/club/garden.jpg",
        "/images/club/garden2.jpg",        
      ],
      index: null,
      options: {
        closeOnSlideClick: true
      }
    };
  }
};
</script>

<style lang="scss">
.weddings .background-item {
  background-color: black !important;
}

.image-text {
  img {
    width: 80%;
  }
}

.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}
</style>
