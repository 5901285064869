var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'grid',
    _vm.columns ? 'c' + _vm.columns : '',
    _vm.alignRight ? 'align-right' : '',
    _vm.noWrap ? 'no-wrap' : '',
    _vm.pad ? 'grid-pad' : '',
    _vm.center ? 'center-horizontally' : '',
    _vm.middle ? 'center-vertical' : '',
    _vm.noGrow ? 'no-grow' : '',
    _vm.vertical ? 'vertical' : '',
    _vm.fill ? 'fill' : '',
    _vm.debug ? 'debug' : ''
  ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }