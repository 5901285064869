<template>
  <div class="visiting top">
    <!-- Hero -->
    <ViewportHeight top>
      <FullBackground
        class="hero blue-fade-out"
        :opacity="0.8"
        :image="splashImageUrl"
      >
        <Section class="big-info" fill center wrap>
          <Grid center fill>
            <Column
              center
              middle
              :columnWidth="66"
              below-tablet-text-center
              :below-tablet-width="100"
              class="hero-header-text"
            >
              <h1>
                Room Hire
              </h1>
              <p>
                The Wolstanton Conservative Club is located in the heart of the village with easy access to local parking, 
                and with such a large space available it is no wonder
                we host regular private functions in the club.
              </p>
            </Column>
          </Grid>
        </Section>
      </FullBackground>
    </ViewportHeight>

    <Section class="big-info works gradient-back invert " fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <div class="room-hire-list">
            <p>
              There are a number of benefits that come from hiring our rooms for your events such as:
              <ul>
                <li>
                  Private Venue
                </li>
                <li>
                  100 plus seating
                </li>
                <li>
                  Licensed to play music and live events
                </li>
                <li>
                  Fully stocked and Licensed bar
                </li>
              </ul>
            </p>
          </div>
        </Column>
        <Grid center>
          <Column class="facilities" :below-laptop-width="80">
            <img :src="gamesURL" />
            <h2><u>Games Room</u></h2>
          </Column>
          <Column class="facilities" :below-laptop-width="80">
            <img :src="loungeURL" />
            <h2><u>Lounge</u></h2>
          </Column>
          <Column class="facilities" :below-laptop-width="80">
            <img :src="stageURL" />
            <h2><u>Stage Room</u></h2>
          </Column>
          <Column class="facilities" :below-laptop-width="80">
            <img :src="gardenURL" />
            <h2><u>Garden</u></h2>
          </Column>
        </Grid>
      </Grid>
    </Section>

    <Section class="big-info gradient-back step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>Would you like us to host your event?</h1>
          <p>
            You can speak directly to our secretary via our contact page,
            simply click the button below to fill out our online form, and we will get back to you.
          </p>
          <div class="button-cont">
            <router-link to="/contact" class="button big" style="margin-bottom: unset !important;">Contact Page</router-link>
          </div>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="gamesURL"> </FullBackground>
    </div>
  </div>
</template>

<script>
export default {
  name: "visiting",
  components: {},
  data() {
    return {
      club1Url: "/images/club/bar-2.jpg",
      gamesURL: "/images/club/billiards.jpg",
      loungeURL: "/images/club/lounge.jpg",
      stageURL: "/images/club/stage.jpg",
      gardenURL: "/images/club/garden2.jpg",
      splashImageUrl: "/images/club/lounge.jpg",
      downArrowUrl: "/images/down-arrow.svg",
      walkUrl: "/images/walk.svg",
      hairDryerUrl: "/images/hair-dryer.svg",
      entranceImageUrl: "/images/visit/entrance.jpg",
      studioImageUrl: "/images/heros/home.jpg",
      magsUrl: "/images/magazine.svg",
      hairGelUrl: "/images/hair-gel.svg",
      hairDyeUrl: "/images/hair-dye.svg",
      coffeeUrl: "/images/coffee-cup.svg",
      barberUrl: "/images/barbershop.svg",
      washUrl: "/images/hair-washing.svg",
      curlUrl: "/images/curl.svg",
      hairCutUrl: "/images/hair-cut.svg",
      walletUrl: "/images/wallet.svg",
      magazineImageUrl: "/images/visit/magazines.jpg",
      hairImageUrl: "/images/visit/hair.jpg",
      hairDyeImageUrl: "/images/visit/hair-dye.jpg",
      coffeeImageUrl: "/images/visit/coffee.jpg",
      scissorsImageUrl: "/images/visit/scissors.jpg",
      washImageUrl: "/images/visit/wash.jpg",
      productImageUrl: "/images/visit/product.jpg",
      styleImageUrl: "/images/visit/style.jpg",
      hairDryImageUrl: "/images/visit/hair-dry.jpg",
      counterImageUrl: "/images/visit/counter.jpg"
    };
  }
};
</script>

<style lang="scss" scoped>
.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.step-inside {
  img {
    width: 2em;
  }
}

.downArrow {
  @extend .pb2;

  position: relative;
  opacity: 0.5;
  z-index: 2;

  width: 10vh;
  margin: auto;
}
.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
</style>
