<template>
  <div class="fill-centered">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    // If true, wraps the content to the site max width
    wrap: Boolean,
    // If true, pads out the content
    pad: Boolean
  }
};
</script>

<style lang="scss" scoped></style>
