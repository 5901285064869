<template>
  <div class="products top">
    <!--Entertainment Hero -->
    <ViewportHeight top>
      <FullBackground class="hero" :opacity="1" :image="rangeImageUrl">
        <Grid fill wrap vertical no-grow center>
          <Column center class="pad2">
            <Grid center>
              <Column :column-width="80" :below-laptop-width="100">
              </Column>
            </Grid>
          </Column>
        </Grid>
      </FullBackground>
    </ViewportHeight>

    <!-- Entertainment Sub-Hero -->
    <Section pad wrap center class="big-info gradient-back invert">
      <h1>
        Entertainment & Events
      </h1>
      <p>
        With a fully licensed bar, dedicated stage and dance floor we constantly provide a great atmosphere for all of our live entertainment and sporting events.
        Please see below for our comprehensive list of entertainment coming up, 
        we update this list so please keep coming back to this page to see what have lined up for the future.
        <br />
      </p>
    </Section>
    
    <Section pad wrap class="big-info gradient-back ghd">
      <!-- <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">Samantha Lloyd - Saturday 22nd January</h1>
            <p>
              Fantastic Personality and Vocal Entertainer <em>Samantha</em> will be entertaining our members 
              and guests during our first event of the New Year.
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="samanthaURL" />
          </div>
        </Column>        
      </Grid> -->
      <!-- <hr>
       -->
      
      <!-- <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">Kelly Wilmore - Saturday 30th April</h1>
            <p>
              Fantastic Personality and Vocal Entertainer <em>Kelly Wilmore</em> will be singing the great 
              classics for our members & guests in April.
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="kellyWilmoreURL" />
          </div>
        </Column>        
      </Grid>
      <hr>
      <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">Les Carlton - Saturday 21st May</h1>
            <p>
              Fantastic Personality and Vocal Entertainer <em>Les Carlton</em> will be singing the great 
              classics for our members & guests in May.
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="lesCarltonURL" />
          </div>
        </Column>        
      </Grid>
      <hr> -->
      <!-- <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">Helen Yardley - Saturday 25th June</h1>
            <p>
              Fantastic Personality and Vocal Entertainer <em>Helen Yardley</em> will be singing the great 
              classics for our members & guests in June.
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="helenYardleyURL" />
          </div>
        </Column>        
      </Grid> -->

      <!-- <hr>
      <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>             
            <h1 class="tight">Rick James - Saturday 24th September</h1>
            <p>
              This month the great Guitar Vocalist <em>Rick James</em> will be providing entertainment for our special charity event which is all in aid of Help For Heroes. 
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="rickJamesURL" />
          </div>
        </Column>        
      </Grid> -->
      <hr>

      <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">Starlites - Saturday 29th October </h1>
            <p>
              The Starlites will be treating our members to all the fantastic songs and entertainment that makes this well known act a highlight of the month.
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="entertainmentURL" />
          </div>
        </Column>        
      </Grid>

      <hr>
      <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">Jamie McGrath- Saturday 26th November</h1>
            <p>
              Jamie will be providing top of the line entertainment for our members this month, with great songs from Elvis and friends.
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="entertainmentURL" />
          </div>
        </Column>        
      </Grid>
      <hr>

      <Grid class="pt2 pb2" pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">Jays Disco - Saturday 31st December</h1>
            <p>
              What a way to round off the year. The highlight of the month will be Jay's Disco as we celebrate all of 2022 and welcome in 2023.
              <br/><br/>
              <i><b>NOTE: This is a ticketed event, so please ensure you get your tickets well in advance.</b></i>
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img class="events-img" :src="entertainmentURL" />
          </div>
        </Column>        
      </Grid>

    </Section>
    
  </div>
</template>

<script>
export default {
  name: "products",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/backgrounds/abstract-geometry.svg",
      rangeImageUrl: "/images/club/stage.jpg",
      craigJasonURL: "/images/events/craig-jason-1.jpg",
      schwarzkopfLogoUrl: "/images/range/logo-schwarzkopf.png",
      ghdBoxUrl: "/images/range/ghd.jpg",
      americanCrewLogoUrl: "/images/range/logo-american-crew.svg",
      americanCrewUrl: "/images/range/products.jpg",
      colorsUrl: "/images/range/colors.jpg",
      igoraTubeUrl: "/images/range/igora.png",
      entertainmentURL: "/images/events/live-entertainment.jpg",
      lisaURL: "/images/events/lisa.jpg",
      samanthaURL: "/images/events/Samantha.jpg",
      wayneRobertsURL: "/images/events/Wayne-Roberts.jpg",
      kellyWilmoreURL: "/images/events/Kelly-Wilmore.jpg",
      lesCarltonURL: "/images/events/Les-Carlton.jpg",
      helenYardleyURL: "/images/events/Helen-Yardley.jpg",
      rickJamesURL: "/images/events/Rick-James.jpg",
            
    };
  }
};
</script>

<style lang="scss" scoped>
.ghd {
  @include media($breakpoint-below-laptop) {
    text-align: center;

    p {
      margin-bottom: 3em;
    }
  }
}

.igora {
  @include media($breakpoint-below-laptop) {
    text-align: center;
  }
}

.ghd-box {
  margin-top: -6em;

  @include media($breakpoint-below-laptop) {
    margin-top: 0em;
  }
  img {
    width: 100%;
  }
}

.american-crew {
  img {
    width: 50%;
  }
}

.colors {
  background: black !important;

  color: var(--color-dark-background-text);
}

.schwarzkopt {
  @include media($breakpoint-below-laptop) {
    padding-top: 3em;
  }

  img {
    width: 70%;
  }
  .blush-wash {
    @include media($breakpoint-below-laptop) {
      height: 50%;
    }

    overflow: hidden;
    height: 100%;

    > div {
      height: 100%;

      @include media($breakpoint-below-laptop) {
        height: 150%;
      }
      > div {
        background-image: url("/images/range/blush-wash.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;

        // Float right side
        @include media($breakpoint-above-tablet) {
          position: absolute;
          background-position: left 10em;
          left: 55vw;
          right: 0;
        }

        @include media($breakpoint-laptop) {
          left: 50vw;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 25%;
          bottom: 0;
          background: linear-gradient(to bottom, transparent, #fff);
        }
      }
    }
  }
}

.range {
  width: 100%;

  > div {
    height: 100%;

    background-position: top center;
    background-size: cover;
    margin: 0 auto;
    background-repeat: no-repeat;
  }
}

.hero {
  border-bottom: 2px solid;
  border-color: var(--color-primary);

  h1 {
    margin-bottom: 0.8em;
  }
}
</style>
